import React from 'react';
import { Box, Button, Typography } from '@heycater/design-system';
import Image from 'next/image';
import { StaticImageData } from 'next/image';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Inspiration1 from '@images/homepage/inspiration1.png';
import Inspiration2 from '@images/homepage/inspiration2.png';
import Inspiration3 from '@images/homepage/inspiration3.png';
import { useBreakpointDown } from 'qualification/hooks/useBreakpoints';

export default function Inspirations() {
  const { t } = useTranslation('homepage');
  const mobile = useBreakpointDown('sm');

  if (mobile) return null;

  return (
    <Wrapper>
      <Title>{t('inspirations.title')}</Title>
      <Subtitle>{t('inspirations.subtitle')}</Subtitle>
      <Box my={4}>
        <Box display="flex" justifyContent="space-between">
          <Card
            image={Inspiration1}
            title={t('inspirations.card1.title')}
            body={t('inspirations.card1.body')}
            ctaText={t('inspirations.card1.cta')}
            link="https://www.heycater.com/de/hub/hey-wissen/firmenevent-planen"
          />
          <Card
            image={Inspiration2}
            title={t('inspirations.card2.title')}
            body={t('inspirations.card2.body')}
            ctaText={t('inspirations.card2.cta')}
            link="https://www.heycater.com/de/hub/hey-wiki/flying-buffet"
          />
          <Card
            image={Inspiration3}
            title={t('inspirations.card3.title')}
            body={t('inspirations.card3.body')}
            ctaText={t('inspirations.card3.cta')}
            link="https://www.heycater.com/de/hub/hey-wiki/was-ist-catering-eine-definition"
          />
        </Box>
      </Box>
      <Box display="flex" justifyContent="center" mt={6}>
        <a
          href="https://www.heycater.com/de/hub/hey-community"
          target="_blank"
          rel="noreferrer"
        >
          <CTAButton variant="secondary">{t('inspirations.cta')}</CTAButton>
        </a>
      </Box>
    </Wrapper>
  );
}
interface CardProps {
  title: string;
  body: string;
  image: StaticImageData | string; // If image is React component (SVG) or a URL string
  ctaText: string;
  link: string;
}

function Card({ image, title, body, ctaText, link }: CardProps) {
  return (
    <CardWrapper>
      <Image alt="" src={image} height={212} width={351} />
      <Heading>{title}</Heading>
      <Body>{body}</Body>
      <a href={link || ''} target="_blank" rel="noreferrer">
        <StyledButton variant="tertiary">{ctaText}</StyledButton>
      </a>
    </CardWrapper>
  );
}

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing(2)}px !important;
  max-width: 351px;
  img {
    border-radius: ${({ theme }) => theme.spacing(1)}px;
  }
`;

const CTAButton = styled(Button)`
  border-radius: 47px;
`;

const Heading = styled(Typography)`
  font-size: 18px;
  font-weight: 600;
  line-height: 30.24px;
`;

const Body = styled(Typography)`
  font-size: 16px;
  line-height: 26.88px;
  font-weight: 400;
`;

const StyledButton = styled(Button)`
  border-radius: ${({ theme }) => theme.spacing(1)}px;
  width: min-content;
  font-size: 14px;
`;

const Wrapper = styled(Box)`
  width: 1118px;
  margin: auto;
`;

const Title = styled(Typography)`
  font-size: 36px;
  line-height: 62.1px;
  font-weight: 700;
  text-align: center;
`;

const Subtitle = styled(Typography)`
  font-size: 16px;
  line-height: 26.88px;
  font-weight: 400;
  text-align: center;
  margin-top: ${({ theme }) => theme.spacing(1)}px;
`;
